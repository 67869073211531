<template>
  <div>
    <div class="top">
      <div class="headerTop">
        <img src="../assets/img/logo.png" class="logoimg" />
        <div class="logtext">深圳市承佰意科技有限公司</div>
        <!-- <div>
          <ul>
            <li :class="isSelect == 1 ? 'colborder' : ''" @click="goList(1)">
              公司简章
            </li>
            <li :class="isSelect == 2 ? 'colborder' : ''" @click="goList(2)">
              产品介绍
            </li>
            <li :class="isSelect == 3 ? 'colborder' : ''" @click="goList(3)">
              经典案例
            </li>
            <li :class="isSelect == 4 ? 'colborder' : ''" @click="goList(4)">
              企业资质
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="banner full-box">
      <div class="word-container common-box">
        <span class="w1">您值得拥有</span>
        <span class="w2">一个充满科技和艺术的数据服务平台</span>
        <span class="w3">就在佰意数科 chengbaiyi.com</span>
      </div>
    </div>
    <div class="parent-box-col">
      <div class="title">介绍<span class="orange-color">公司</span>做什么</div>
      <div class="section">
        <div class="imgOne"></div>
        <div class="text-intro">
          <div>
              深圳市承佰意科技有限公司是一支最具活力和最具竞争力的商务管理软件科研开发商之一,是中国软件产业的杰出代表。自成立以来，深圳市承佰意科技有限公司时刻关注着物流行业管理软件市场的需求，以中小企业管理的规范化、科学化、现代化为已任，把适合中国国情的物流行业管理软件开发当做自己的使命，研发出一系列识别软件，身份证OCR、驾驶证OCR、行驶证OCR、道运证OCR、银行卡OCR、营业执照OCR、身份证二元素认证、银行卡三四元素认证、企业三元素认证，满足了不同企业的需求。          </div>
          <div>
            经过多年努力，深圳市承佰意科技有限公司的客户拥有量和市场占有率迅速扩大，企业设计的软件赢得了全国各地用户的好评。经过不断的积累与发展，公司的科研队伍不断壮大。公司拥有一支由计算机软件专家和商务管理专家共同组成的一流的科研开发队伍。
          </div>
        </div>
      </div>
    </div>
    <div class="parent-box">
      <div class="title">
        再看看<span class="orange-color">产品</span>有哪些
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">身份证OCR</div>
            <div class="left-box-val">
              精准识别二代居民身份证正反两面的全部8个字段，全方位满足身份证使用场量中对任意字段的识别需求。检测并告警身份证图片存在的风险或质量问题，包括复印、翻拍、ps等多类风险，以及边框不完整、头像被遮挡等多种质量问题。使用身份证识别技术，自动识别录入用户身份信息，可应用于金融、保险、电商、020、直播等场景，对用户、商家、主播等进行实名身份认证，有效降低用户输入成本，控制业务风险。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img1.png" class="logo-img" />
          </div>
        </div>
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">驾驶证OCR</div>
            <div class="left-box-val">
              结构化识别机动车驾驶证正页及副页所有15个字段，包括证号、姓名、性别、国籍、住址、出生日期、初次领证日期、准驾车型、有效期限、发证单位、档案编号等。同时支持识别交管12123
              APP
              发放的电子驾驶证正页，包括证号、姓名等全部15个字段。精准识别驾驶证正页及副页、电子驾驶证正页的全部字段，不同业务场景下快速提取任意字段信息，满足各类应用需求。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img2.png" class="logo-img" />
          </div>
        </div>
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">行驶证OCR</div>
            <div class="left-box-val">
              行驶证识别系统是易泊时代基于业界领先OCR识别技术，自主研发的一款OCR识别产品，能够快速精准地识别国内、国外各类行驶证信息，支持行驶证正副页全字段识别输出，秒级响应，识别精度高达99%以上，支持移动端SDK等多种形式使用。支持行驶证正副页号牌号码、车辆类型、所有人、住址、品牌型号、车辆识别代号等全字段识别输出。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img3.png" class="logo-img" />
          </div>
        </div>
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">道运证OCR</div>
            <div class="left-box-val">
             结构化识别全国各地的道路运输证，包括业户名称、地址、车轿号牌、经警许可证、车辆规格、初领日期、发证日期等14个关键字段。可识别全国各地横版及竖近的道路运输证，使用海量多场景业务数据训练调优，满足各英使用场景的信息提取需求，针对拍摄角度异位、背最及亮度干扰、内容情位等常见问题进行专项忧化。识别准确率高。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img4.png" class="logo-img" />
          </div>
        </div>
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">银行卡OCR</div>
            <div class="left-box-val">
              结构化识别银行卡号、有效期等关键字段信息，可根据识别的卡号判断发卡行及卡片类型，满足各类相关业务需求。可针对多类型、多角度的银行卡数据进行专项训练，可精准识别借记卡、贷记卡等多角度旋转的银行卡照片，系统提供高可靠性、弹性可伸缩、高并发承载的文字识别公有云服务，服务可用性高达99.99%。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img5.png" class="logo-img" />
          </div>
        </div>
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">营业执照OCR</div>
            <div class="left-box-val">
              营业执照识别是勤正数科基于业界领先OCR识别技术，自主研发的一款OCR识别产品，支持多种样式营业执照的识别，提取和输出统一社会信用代码、公司名称、地址等多种关键信息。支持识别三证合一、五证合一、横版、竖版以及工商定义的多种格式的营业执照，支持提取和输出统一社会信用代码、公司名称、地址等全部字段信息。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img6.png" class="logo-img" />
          </div>
        </div>
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">身份证二元素认证</div>
            <div class="left-box-val">
              通过传递姓名+身份证号码，效验信息是否一致，支持信息加密传输方式，可与身份证Ocr技术融合使用。全面、快速地核验用户真实身份信息，用于物流、电商、游戏、直播、金融等需要身份证证件的场景。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img7.png" class="logo-img" />
          </div>
        </div>
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">银行卡三四元素认证</div>
            <div class="left-box-val">
              银行卡三要素认证是指验证姓名和持卡人身份证号及银行卡号是否一致。基于银行数据，核对身份证号、银行卡号和开户姓名是否匹配，在用户进行银行卡绑定的过程中，判断是否存在虚假信。
              银行卡四要素实名认证是指，输入身份证号、姓名、手机号、银行卡号，判断是否匹配。在用户绑卡或提现环节做好严格风控，通过四种维度精确判断用户输入的信息是否与银联登记的一致。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img8.png" class="logo-img" />
          </div>
        </div>
      </div>
      <div class="product-box-div">
        <div class="Card">
          <div class="left-box">
            <div class="left-box-title">企业三元素认证</div>
            <div class="left-box-val">
              通过企业名称，统一社会信用代码，姓名查询企业状态与相关信息，数据产品须应用于自有真实业务场景，数据产品需求方使用数据产品时须得到用户的自主真实授权，数据产品应用场景需合理合法。
            </div>
          </div>
          <div class="right-box">
            <img src="../assets/img/img9.png" class="logo-img" />
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="footer-box">
        <div class="flex-row">
          <div>
            <div class="val1">联系我们</div>
            <div class="val2">132 6652 7803</div>
            <div class="val2">刘先生</div>
          </div>
          <div>
            <div class="val1">企业地址</div>
            <div class="val2">
              深圳市南山区南头街道马家龙社区虹步路15号马家龙19栋306H28
            </div>
          </div>
          <div>
            <div class="val1">扫码咨询</div>
            <img src="../assets/img/wx.jpg" class="dimensional" />
          </div>
        </div>
      </div>
      <div class="footer-container">Copyright @ 版权所有 陕ICP备2023007521号-1</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      isSelect: 1,
    };
  },
  methods: {
    goList(val) {
      this.isSelect = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 *{
  font-family:'微软雅黑';
 }
.top {
  width: 100%;
  height: 74px;
  /* position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  right: 0; */
  display: flex;
  justify-content: center;
  background-color: #fff;
  list-style: none;
}
.headerTop {
  height: 74px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 1335px;
  min-width: 1335px;
}
.logo-img {
  width: 184px;
  height: 70px;
}
.logoimg {
  height: 45px;
}
.logtext{
  font-size: 25px;
  margin-left: 30px;
  font-weight: 600;
  color: #545454;
}
ul {
  height: 74px;
  display: flex;
  justify-content: space-around;
  width: 360px;
  list-style: none;
}
.colborder {
  border-top: 1px solid #fa6400;
  border-bottom: 1px solid #fa6400;
  color: #fa6400;
}
li {
  height: 72px;
  cursor: pointer;
  top: 2px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.full-box {
  width: 100%;
}
.banner {
  /* margin-top: 74px; */
  width: 100%;
  height: 441px;
  background-image: url("../assets/img/banner.6bd3c70.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.word-container {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  position: relative;
}
.banner span {
  display: block;
  color: #fff;
  /* opacity: 0; */
  position: absolute;
}
.common-box {
  width: 1335px;
}
.w1 {
  /* animation: 1.5s 1.5s alternate forwards; */
  left: 217px;
	font-size: 24px;
	top: -128px;

}
.w2 {
  left: 312px;
    font-size: 48px;
    top: -74px;
}
.w3 {
  left: 1002px;
    font-size: 24px;
    top: 9px;
}
.parent-box-col {
  background-color: #f6f6f6;
  padding-top: 56px;
}
.parent-box {
  padding-top: 56px;
}
.orange-color {
  color: #fa6400;
}
.title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin: 0 0 56px;
  font-weight: 600;
  list-style: none;
}
.section {
  display: flex;
  width: 1335px;
  margin: 0 auto;
}
.imgOne {
  width: 426px;
  height: 310px;
  background-image: url("../assets/img/company-left.0e5085f.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}
.text-intro {
  margin-left: 53px;
  margin-top: -11px;
  flex: 1;
  height: 324px;
  font-size: 16px;
  /* font-family: PingFangSC-Regular,PingFang SC; */
  font-weight: 400;
  color: #666;
  line-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.text-intro div {
  text-indent: 2em;
}
.product-box-div {
  width: 1335px;
  margin: 0 auto;
  /* display: inline-block; */
  padding-bottom: 49px;
}
.Card {
  margin-top: 54px;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  height: 240px;
}
.left-box {
  text-align: left;
  margin-right: 40px;
  border-bottom: 1px solid #dedede;
}
.left-box-title {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  line-height: 30px;
}
.left-box-val {
  max-width: 908px;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  line-height: 36px;
  /* text-indent: 2em; */
}
.right-box img {
  width: 375px;
  height: 240px;
  display: inline-block;
}
.foot {
  height: 283px;
  background-color: #404040;
}
.footer-box {
  background-color: #4c4c4c;
  height: 221px;
}
.flex-row {
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 14px;
  width: 1335px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
.val1 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  line-height: 36px;
}
.val2 {
  font-size: 16px;
  font-weight: 400;
  color: #c5c5c5;
  line-height: 29px;
}
.dimensional {
  width: 132px;
  height: 132px;
  display: inline-block;
}
.footer-container {
  font-size: 16px;
  font-weight: 400;
  color: #838383;
  text-align: center;
  line-height: 61px;
}
</style>
